@import '@fontsource/poppins';
@import "@fontsource/poppins/200.css";
@import "@fontsource/poppins/300.css";
@import "@fontsource/poppins/400.css";
@import "@fontsource/poppins/500.css";
@import "@fontsource/poppins/600.css";
@import "@fontsource/poppins/700.css";
@import "@fontsource/poppins/800.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: "Poppins";
}

.heading-text-1 {
  font-family: "Poppins";
  font-size: 26px;
  font-weight: bolder;
  line-height: 45px;
  letter-spacing: 0.02em;
  color: white;
}

.body-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;
  color: white;
}

.body-text-2 {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

.body-text-3 {
  font-family: Poppins;
  font-size: 8.25px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
}

.input-field-label-text {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
}

.welcome-back-text {
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
}

.button-linear-text {
  background: linear-gradient(to left, #B68340,  #E2DB97);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.button-linear-text2 {
  background: linear-gradient(to left, #184332,  #50623A);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.button-dashboard-tab-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.15399999916553497px;
  text-align: left;
  color : #828282
}

fieldset{
  border-top: 2px solid #F2F2F2;
  /*border-top-color: black;*/
  box-sizing: border-box;
  /*grid-area: 1 / 1;*/
  width: inherit;
}

legend{
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.07999999821186066px;
  text-align: center;
  color: #718096;
  padding: 0 10px;
}

.form-helper-text {
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.07999999821186066px;
  text-align: center;
  color: #718096;
  padding: 0 10px;
}

.OAuthIconButton {
  height: 46px;
  width: 46px;
  /*border: 1px solid #718096;*/
  border: 1px #F2F2F2 solid;
  box-shadow: 0 0 1.5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.profile-setup-logo {
  height: 60px;
  width: 60px;
  left: 40px;
  top: 20px;
  /*border: 1px solid #718096;*/
  border: 1px #F2F2F2 solid;
  box-shadow: 0 0 1.5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.textfield input::placeholder {
  font-family: "Poppins";
  font-size: 8px;
  font-weight: 500;
  padding: 0 5px;
  color: #7A828A;
}

.signup-tnc {
  font-family: Poppins;
  font-size: 9px;
  line-height: 20px;
}

.tnc-bold {
  font-family: Poppins;
  font-size:9px;
  line-height: 20px;
  font-weight: 700;
}

.selectOptionsDropdownMenu {
  margin: 0 15px
}

.MuiList-root > li:not(:first-child):hover {
  background-color: #FAF8E5 !important;
  font-weight: 600;
}

.MuiList-root > .selectOptionsDropdownMenu2:not(:first-child):hover {
  background-color: #E4FFF4 !important;
  font-weight: 600;
}

.MuiList-root > li {
  font-family: Poppins;
}

.MuiPaper-root {
  border-radius: 15px !important;
}

.MuiList-root > :first-child {
  background-color: white !important;
  font-weight: 500;
}

.navbarMenuItem {
  font-size: 13px;
  color: #828282;
  font-weight: 400;
  font-family: Poppins
}

.navbarMenuItemActive {
  font-size: 13px;
  color: #B68340;
  font-weight: 400;
  font-family: Poppins
}

/*.navbarMenuItem :hover {*/
/*  font-size: 12px;*/
/*  color: #828282;*/
/*  font-weight: 500;*/
/*  font-family: Poppins*/
/*}*/

.navbarMenuItemContainer {
  margin-left: 25px;
  margin-right: 25px;
}

.navbarMenuItemActive {
  background-color: #E4FFF4;
  border-radius: 8px;
  font-weight: 500;
  color: #B68340;
  cursor: pointer;
}

.navbarMenuItemContainer :hover{
  background-color: #E4FFF4;
  border-radius: 8px;
  font-weight: 500;
  color: #B68340;
  cursor: pointer;
}

.navbarMenuItemContainer :hover p{
  color: #B68340;
  font-weight: 500;
}

.navbarMenuItemContainer :hover p svg{
  color: #B68340;
  font-weight: 500;
}

.MuiDrawer-root > .MuiPaper-root {
  border-radius: 0 !important;
}

.chart-subtitle {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  color: #184332;
}

.chart-amount-count {
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0;
}

.chart-amount-count-2 {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0;
  color: #27AE60;
}

.card-items-title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.kpi-section-gauge-card {
  width: 400px;
  height: 197px;
  top: 277px;
  left: 258px;
  border-radius: 12px
}

.kpi-section-filter-by-text {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #475569;
}


.kpi-section-filtered-by {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #94A3B8;
}

.kpi-card-2-subtitle {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #7D7D7D;
}


.kpi-chart-amount-count {
  font-family: Poppins;
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0;
  color: #B68340;
}

.accordion-title {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.accordion-subtitle {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left;
}

.accordion-unlock-time {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.0125em;
  text-align: left;
}

.accordion-unlock-title {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-left: 3px;
}

.MuiAccordion-root > .Mui-expanded {
  background-color: #184332;
  color: white;
  border-radius: 15px 15px 0 0;
}

.MuiAccordion-root > .MuiCollapse-root {
  background-color: #184332;
  color: white;
  border-radius: 0 0 15px 15px;
}

.chartContainer1 {
  width: 400px;
  height: 220px;
  border-radius: 12px
}

@layer components {
  .golden{
    background-image:linear-gradient(to right, #A9793A, #E1DA96, #B08746);
  }
  .golden-txt {
    background-image:linear-gradient(to right, #A9793A, #E1DA96, #B08746);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}